import React from "react";

import { Helmet } from "react-helmet";

import { Fragment } from "react";

import parse from "html-react-parser";

import { graphql } from "gatsby";

import { useEffect } from "react";

import Breadcrumbs from "@Js/Component/Breadcrumbs/Breadcrumbs";

import classNames from "@Css/Component/Template/Page.module.scss";

import "@Css/Wordpress/EntryContent.module.scss";
import "@Css/Wordpress/WpBlockButtons.module.scss";
import "@Css/Wordpress/WpBlockColumns.module.scss";
import "@Css/Wordpress/WpBlockCover.module.scss";
import "@Css/Wordpress/WpBlockGallery.module.scss";
import "@Css/Wordpress/WpBlockImage.module.scss";
import "@Css/Wordpress/WpBlockLatestPosts.module.scss";
import "@Css/Wordpress/WpBlockQuote.module.scss";
import "@Css/Wordpress/WpBlockSeparator.module.scss";
import "@Css/Wordpress/WpBlockTable.module.scss";

export type PageProps = Readonly<{
    data: {
        wordpressPage: {
            content: string;
            title: string;
            yoast_breadcrumbs: {
                text: string;
                url: string;
            }[];
            yoast_head: string;
        };
    };
}>;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            content
            title
            yoast_breadcrumbs {
                text
                url
            }
            yoast_head
        }
    }
`;

const Page = (props: PageProps) => {
    const page = props.data.wordpressPage;

    useEffect(() => {
        /**
         * Quick fix to fix anchor scrolling not work in Gatsby
         */
        if (!(typeof document == "undefined")) {
            document.body.addEventListener("click", (event) => {
                const anchors = document.querySelectorAll("[href^='#']");

                for (let anchor of anchors) {
                    if (event.target == anchor) {
                        event.preventDefault();

                        const element = document.querySelector(anchor.getAttribute("href") as string);

                        if (element) {
                            element.scrollIntoView({
                                behavior: "smooth"
                            });
                        }
                    }
                }
            }, true);
        }
    }, []);

    return (
        <Fragment>
            <Helmet>
                { parse(page.yoast_head) }
            </Helmet>
            <Breadcrumbs breadcrumbs={ page.yoast_breadcrumbs } />
            <main>
                <article>
                    <header className={ classNames.header }>
                        <h1 className={ classNames.title }>{ parse(page.title) }</h1>
                    </header>
                    <div className="entry-content">
                        { parse(page.content) }
                    </div>
                </article>
            </main>
        </Fragment>
    );
};

export default Page;
